import React, { useRef, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import UndoIcon from 'assets/svg/icons/undo.svg';
import CheckIcon from 'assets/svg/icons/check.svg';

import s from './Signature.scss';

interface IProps {
  onComplete: (data: string) => void;
  placeholder?: string;
  instructions?: string;
}

export const Signature = ({ onComplete, placeholder, instructions }: IProps) => {

  const canvas = useRef<HTMLCanvasElement>(null);

  const [isDrawing, setIsDrawing] = useState(false);
  const [previousPosition, setPreviousPosition] = useState<any>({x: 0, y: 0});
  const [hasContent, setHasContent] = useState(false);

  const updatePreviousPosition = (x: number, y: number) => {
    setPreviousPosition({ x, y });
  };

  useEffect(() => {
    if (typeof window === undefined) {
      return;
    }

    window.addEventListener('touchmove', (e) => onTouchMove(e), { passive: false });
    return () => {
      window.removeEventListener('touchmove', (e) => onTouchMove(e));
    };
  }, []);

  let currentX: number;
  let currentY: number;

  // lock scroll on touch events
  const onTouchMove = (e: any) => {
    if (e.target !== canvas.current) { return; }
    e.preventDefault();
  };

  function onCompleteHandler() {
    let data = '';

    if (canvas.current) {
      data = canvas.current.toDataURL();
    }

    onComplete(data);
  }

  const onMove = (eventType: string, event: any) => {
    const ctx = canvas.current!.getContext('2d');
    if (!ctx) { return; }

    const touches = event.touches;

    const computeOffset = (e: any) => {
      const rect = canvas.current!.getBoundingClientRect();

      return {
        x: Math.floor((e.clientX - rect.left) / (rect.right - rect.left) * canvas.current!.offsetWidth),
        y: Math.floor((e.clientY - rect.top) / (rect.bottom - rect.top) * canvas.current!.offsetHeight),
      };
    };

    if (touches && touches[0]) {
      currentX = computeOffset(touches[0]).x;
      currentY = computeOffset(touches[0]).y;

    } else if (event.clientX && event.clientY) {
      currentX = computeOffset(event).x;
      currentY = computeOffset(event).y;
    }

    if (eventType === 'start') {
      updatePreviousPosition(currentX, currentY);
      setIsDrawing(true);
    }

    if (eventType === 'move') {
      if (isDrawing) {

        updatePreviousPosition(currentX, currentY);
        requestAnimationFrame(() => { draw(); });
      }
    }
  };

  const draw = () => {
    const ctx = canvas.current!.getContext('2d');
    if (!ctx) { return; }

    ctx.beginPath();

    ctx.strokeStyle = '#fff';
    ctx.lineWidth = 1.5;

    ctx.moveTo(previousPosition.x, previousPosition.y);
    ctx.lineTo(currentX, currentY);
    ctx.stroke();
    ctx.closePath();

    if (!hasContent) {
      setHasContent(true);
    }
  };

  const onClear = () => {
    const ctx = canvas.current!.getContext('2d');
    if (!ctx) { return; }

    ctx.clearRect(0, 0, canvas.current!.offsetWidth, canvas.current!.offsetHeight);
    setHasContent(false);
  };

  return (
    <div className={s('signature')}>
      <div className={s.signature__inner}>
        <canvas
          ref={canvas}
          className={s.signature__canvas}
          onMouseDown={(e: React.MouseEvent<HTMLCanvasElement>) => {onMove('start', e); }}
          onMouseMove={(e: React.MouseEvent<HTMLCanvasElement>) => {onMove('move', e); }}
          onMouseUp={() => { setIsDrawing(false); }}
          onMouseOut={() => { setIsDrawing(false); }}
          onTouchStart={(e: React.TouchEvent<HTMLCanvasElement>) => {onMove('start', e); }}
          onTouchMove={(e: React.TouchEvent<HTMLCanvasElement>) => {onMove('move', e); }}
          onTouchEnd={() => { setIsDrawing(false); }}
          onTouchCancel={() => { setIsDrawing(false); }}
          width="600"
          height="110"
        />
        {!hasContent && (
          <div className={s.signature__placeholder}>
            {placeholder || (<FormattedMessage id="signature-placeholder" />)}
          </div>
        )}
      </div>
        <div className={s.signature__instructions}>
          {instructions || (<FormattedMessage id="signature-instructions" />)}
        </div>
      {hasContent && (
        <button className={s.signature__complete} onClick={onCompleteHandler}>
          Done
          <CheckIcon className={s.signature__completeIcon} />
        </button>
      )}

      <button className={s('signature__undo', { active: hasContent })} onClick={onClear}>
        <UndoIcon className={s.signature__undoIcon} />
        <span className={s.signature__undoText}>
          <FormattedMessage id="undo" />
        </span>
      </button>
    </div>
  );
};
