import React from 'react';
import { FormattedMessage } from 'react-intl';

import Facebook from 'assets/svg/icons/social/facebook.svg';
import Twitter from 'assets/svg/icons/social/twitter.svg';

import { Link } from 'components/link/Link';

import s from './InlineShare.scss';

interface IProps {
  facebookLink?: string;
  twitterLink?: string;
}

export const InlineShare = ({ facebookLink, twitterLink }: IProps) => {
  if (!facebookLink && !twitterLink) { return null; }

  const createUrls = () => {
    const urls: any = {};
    const url = window.location.href;
    const encodedUrl = encodeURI(url);

    urls.facebook = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
    urls.twitter = `https://twitter.com/intent/tweet?text=${encodedUrl}`;
    urls.pinterest = `https://pinterest.com/pin/create/button/?url=&media=${encodedUrl}`;

    return urls;
  };

  const onClick = (link: string, e: any) => {
    e.stopPropagation();
    const urls = createUrls();

    window.open(urls[link], '_blank', 'width=600,height=300');
  };

  return (
    <ul className={s('inlineShare')}>
      <li className={s.inlineShare__item}>
      {twitterLink && (
        <Link
          className={s.inlineShare__link}
          to={twitterLink}
          aria-label="Twitter"
          onClick={onClick.bind(null, 'twitter')}
        >
          <Twitter className={s('inlineShare__icon', 'inlineShare__iconTwitter')} />
        </Link>
      )}
      {facebookLink && twitterLink && (
        <span className={s.inlineShare__text}><FormattedMessage id="or" /></span>
      )}
      </li>
      <li>
      {facebookLink && (
        <Link
          className={s.inlineShare__link}
          to={facebookLink}
          aria-label="Facebook"
          onClick={onClick.bind(null, 'facebook')}
        >
          <Facebook className={s('inlineShare__icon', 'inlineShare__iconFacebook')} />
        </Link>
      )}</li>
    </ul>
  );

};
